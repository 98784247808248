export const localeToFacebookLocale = {
  ar: 'ar_AR',
  cs: 'cs_CZ',
  da: 'da_DK',
  de: 'de_DE',
  el: 'el_GR',
  en: 'en_US',
  es: 'es_ES',
  et: 'et_EE',
  fi: 'fi_FI',
  fr: 'fr_FR',
  hi: 'hi_IN',
  hr: 'hr_HR',
  id: 'id_ID',
  it: 'it_IT',
  ja: 'ja_JP',
  ko: 'ko_KR',
  nl: 'nl_NL',
  no: 'no_NB',
  'no-NB': 'no_NB',
  pl: 'pl_PL',
  'pt-BR': 'pt_BR',
  'pt-PT': 'pt_PT',
  ro: 'ro_RO',
  ru: 'ru_RU',
  sk: 'sk_SK',
  sl: 'sl_SI',
  sv: 'sv_SE',
  vn: 'vi_VN',
  'zh-cn': 'zh_CN',
  'zh-hk': 'zh_HK',
  'zh-tw': 'zh_TW',
};
export const languageNames = {
  ar: 'اللغة العربية الفصحى',
  cs: 'Čeština',
  da: 'Dansk',
  de: 'Deutsch',
  el: 'Ελληνικά',
  en: 'English',
  es: 'Español',
  et: 'Eesti',
  fi: 'Suomi',
  fr: 'Français',
  hi: 'हिन्दी',
  hr: 'Hrvatski',
  id: 'Bahasa Indonesia',
  it: 'Italiano',
  ja: '日本語',
  ko: '한국어',
  nl: 'Nederlands',
  'no-NB': 'Norsk (bokmål)',
  pl: 'Polski',
  'pt-BR': 'Português (Brasileiro)',
  'pt-PT': 'Português (Portugal)',
  ro: 'Română',
  ru: 'Русский',
  sk: 'Slovenčina',
  sl: 'Slovenščina',
  sv: 'Svenska',
  vi: 'Tiếng Việt',
  'zh-CN': '中文 (Mainland China)',
  'zh-HK': '中文 (Hong Kong)',
  'zh-TW': '中文 (Taiwan)',
};
